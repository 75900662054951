img,
svg {
  user-select: none;
}

body {
  @mixin light {
    --mantine-color-body: #f2f4f7;
    --mantine-color-dimmed: #4d4e4f;
    --mantine-color-error: var(--mantine-color-red-9);
  }
}
